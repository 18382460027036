import React from "react";
import { Box, Heading, Flex, useColorModeValue } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SocialIcon = ({ link, icon, children }) => {
  const socialBg = useColorModeValue("blackAlpha.200", "whiteAlpha.200");
  const socialHoverBg = useColorModeValue("blackAlpha.400", "whiteAlpha.400");
  return (
    <Flex
      flex="1"
      minWidth="180px"
      direction={["row", "row", "column"]}
      alignItems="center"
      justifyContent="flex-start"
      p={4}
      as="a"
      bg={socialBg}
      _hover={{
        bg: socialHoverBg,
      }}
      rounded="md"
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      <Box as={FontAwesomeIcon} m={3} icon={icon} size="4x" />
      <Heading size="sm" as="p">
        {children}
      </Heading>
    </Flex>
  );
};
