import React from "react";
import {
  useColorModeValue,
  Button,
  List,
  ListItem,
  SimpleGrid,
  Heading,
  Image,
  Circle,
  Box,
  Flex,
} from "@chakra-ui/react";
import logo from "../header/figuya-logo-5.png";
import { useParams, Link } from "react-router-dom";
import { SocialIcon, NavLink, Divider } from "../elements";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faTwitch } from "@fortawesome/free-brands-svg-icons/faTwitch";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { useTranslation } from "react-i18next";
import { PaymentTypeSection } from "./PaymentTypeSection";
import { faThreads } from "@fortawesome/free-brands-svg-icons";

const links = {
  en: {
    terms: "/en/terms",
    about: "/en/about",
    legal: "/en/legal",
    shipping: "/en/shipping",
    privacy: "/en/privacy",
    process: "/en/order-process",
    reservations: "/en/reservationinformation",
    cancellation: "/en/cancellation",
    jobs: "/en/jobs",
  },
  de: {
    terms: "/de/agb",
    legal: "/de/impressum",
    about: "/de/ueber-uns",
    shipping: "/de/versandinformationen",
    privacy: "/de/dse",
    process: "/de/bestellablauf",
    reservations: "/de/reservierungsinformationen",
    cancellation: "/de/widerruf",
    jobs: "/de/jobs",
  },
};

export const Footer = () => {
  const { locale } = useParams();
  const { t } = useTranslation();
  const bg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("orange.500", "orange.200");
  const shadow = useColorModeValue("lg", "dark-lg")

  return (
    <>
      <Flex gap="2" mt="250px" bg={bg} alignItems="center" direction="row">
        <Box
          position="relative"
          top="-225px"
          borderColor={borderColor}
          flex="1"
          borderBottomWidth="2px"
        ></Box>
        <Circle
          shadow={shadow}
          alignItems="center"
          justifyContent="center"
          p="12"
          bg="orange.600"
          flexDir="column"
          position="relative"
          top="-225px"
          height={["300px", "450px"]}
          width={["300px", "450px"]}>
          <Image mb="2" src={logo} alt="Figuya Logo" />
          <Box size="sm" color="orange.200" fontWeight="semibold">
            {t("motto")}
          </Box>
        </Circle>
        <Box
          position="relative"
          top="-225px"
          borderColor={borderColor}
          flex="1"
          borderBottomWidth="2px"
        ></Box>
      </Flex >
      <Box bg={bg}>
        <Flex
          py={8}
          px={[2, 4]}
          m="auto"
          maxWidth="1400px"
          direction="column"
          gap="2"
        >
          <SimpleGrid gap="2" width="100%" columns={[1, 2, 3]}>
            <PaymentTypeSection />
            <Flex p={[2, 4]} rounded="lg" direction="column" gap="2">
              <Divider as="div">
                <Heading size="md">Service</Heading>
              </Divider>
              <NavLink to={links[locale].legal}>Impressum</NavLink>
              <NavLink to={links[locale].reservations}>
                {t("reservations")}
              </NavLink>
              <NavLink to={links[locale].shipping}>{t("shipping-info")}</NavLink>
              <NavLink to={links[locale].process}>{t("order-process")}</NavLink>
              <NavLink to={links[locale].terms}>{t("terms")}</NavLink>
              <NavLink to={links[locale].privacy}>{t("privacy")}</NavLink>
              <NavLink to={links[locale].cancellation}>
                {t("cancellation")}
              </NavLink>
              <NavLink to={links[locale].jobs}>Jobs</NavLink>
              <NavLink to={`/${locale}/help-desk`}>FAQ</NavLink>
            </Flex>
            <Flex p={[2, 4]} rounded="lg" direction="column" gap="2">
              <Divider as="div">
                <Heading size="md">Figuya</Heading>
              </Divider>
              <NavLink to={links[locale].about}>{t("about-us")}</NavLink>
              <NavLink to="">{t("figuya-berlin")}</NavLink>
              <NavLink to="">{t("figuya-leipzig")}</NavLink>
              <NavLink to="">{t("figuya-conventions")}</NavLink>
              <NavLink to="/">{t("choose-language")}</NavLink>
            </Flex>
          </SimpleGrid>
          <Divider>
            <Heading size="md" as="div">
              Social Media
            </Heading>
          </Divider>
          <Flex p={[2, 4]} wrap="wrap" gap={2}>
            <SocialIcon link="https://twitter.com/figuchan" icon={faTwitter}>
              Figuya on Twitter
            </SocialIcon>
            <SocialIcon
              link="https://www.facebook.com/Figuya/"
              icon={faFacebook}
            >
              Figuya on Facebook
            </SocialIcon>
            <SocialIcon
              link="https://www.youtube.com/user/Figuya"
              icon={faYoutube}
            >
              Figuya on Youtube
            </SocialIcon>
            <SocialIcon link="https://twitch.tv/figuya" icon={faTwitch}>
              Figuya on Twitch
            </SocialIcon>
            <SocialIcon link="https://www.tiktok.com/@figuya" icon={faTiktok}>
              Figuya on TikTok
            </SocialIcon>
            <SocialIcon
              link="https://www.instagram.com/figuya/"
              icon={faInstagram}
            >
              Figuya on Insta
            </SocialIcon>
            <SocialIcon
              link="https://www.threads.net/@figuya"
              icon={faThreads}>
              Figuya on Threads
            </SocialIcon>
          </Flex>
          <Divider>
            <Heading size="md" as="div">
              {t("contact-us")}
            </Heading>
          </Divider>
          <Flex gap={4} direction="row" wrap="wrap">
            <Flex
              flex="1"
              minWidth="250px"
              p={[2, 4]}
              direction="column"
              gap="2"
            >
              <Heading size="md">{t("about-us")}</Heading>
              <Box fontFamily="heading" letterSpacing="wider">
                {t("about-us-footer")}
              </Box>
            </Flex>
            <Flex
              minWidth="250px"
              p={[2, 4]}
              flex="1"
              direction="column"
              gap="2"
            >
              <Heading as="p" size="md">
                {t("visit-us")}
              </Heading>
              <Box>
                {t("footer-invitation")}
              </Box>
              <Box>
                <Link to={links.store}>
                  <address>
                    Figuya Store Berlin
                    <br />
                    Friedrichstr. 172
                    <br />
                    10117 Berlin
                  </address>
                </Link>
              </Box>
              <Box>
                <address>
                  Figuya Store Leipzig
                  <br />
                  Brühl 74
                  <br />
                  04109 Leipzig
                </address>
              </Box>
              <Box>
                <address>
                  Figuya Store Dresden
                  <br />
                  Prager Str. 4a
                  <br />
                  01069 Dresden
                </address>
              </Box>
              <List>
                <ListItem>
                  <a href="mailto:kontakt@figuya.de">kontakt@figuya.de</a>
                </ListItem>
                <ListItem>
                  <a href="tel:+4930577011989">+49 (0)30 577 011 989</a>
                </ListItem>
              </List>
            </Flex>
            <Flex
              flex="1"
              minWidth="250px"
              p={[2, 4]}
              direction="column"
              gap="2"
            >
              <Heading size="md">{t("originals-title")}</Heading>
              <Box fontFamily="heading" letterSpacing="wider">
                {t("originals-footer")}
              </Box>
            </Flex>
          </Flex>
          <Button variant="link" type="button" data-cc="show-preferencesModal">{t('cookie-preferences')}</Button>
        </Flex>
      </Box>
    </>);
};
